<template>
  <div class="record">
    <p class="title">寄件记录</p>
    <div class="body">
      <van-tabs color="#489ef6" @click="handleClick">
        <van-tab title="待揽收">
          <el-input v-model="billCode">
            <template slot="prepend">运单号</template>
            <el-button
              slot="append"
              @click="getExpress"
              icon="el-icon-search"
            ></el-button>
          </el-input>

          <div
            class="infinite-list list"
            v-infinite-scroll="load"
            style="overflow: auto"
          >
            <div v-for="item in orderlist" :key="item.id" class="main">
              <h5>运单号:{{ item.billCode }}</h5>
              <div class="body">
                <div class="left">
                  <p>杭州市</p>
                  <span>{{ item.senderName }}</span>
                </div>
                <div class="mid">
                  <img src=".././assets/langjt.png" alt="" />
                  <span>待揽收</span>
                </div>
                <div class="right">
                  <p>{{ item.receiverCity }}</p>
                  <span>{{ item.receiverName }}</span>
                </div>
              </div>
              <el-button
                v-if="item.expressCategory == 1"
                @click="handlecancel(item.id)"
                class="btn"
                type="danger"
                circle
                icon="el-icon-delete"
                size="mini"
              ></el-button>
            </div>
          </div>
        </van-tab>
        <van-tab title="已揽收">
          <el-input v-model="billCode">
            <template slot="prepend">运单号</template>
            <el-button
              slot="append"
              @click="getExpress"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <div
            class="infinite-list list"
            v-infinite-scroll="load"
            style="overflow: auto"
          >
            <div v-for="(item, index) in orderlist" :key="item.id" class="main">
              <h5>运单号:{{ item.billCode }}</h5>
              <div class="body">
                <div class="left">
                  <p>杭州市</p>
                  <span>{{ item.senderName }}</span>
                </div>
                <div class="mid">
                  <img src=".././assets/langjt.png" alt="" />
                  <span>{{
                    item.expressActive == 1 ? "运输中" : "已签收"
                  }}</span>
                </div>
                <div class="right">
                  <p>{{ item.receiverCity }}</p>
                  <span>{{ item.receiverName }}</span>
                </div>
              </div>
              <van-collapse
                @change="handleChange(item.id)"
                v-model="activeNames"
              >
                <van-collapse-item title="物流轨迹" :name="index">
                  <p class="li" v-for="(item, index) in list" :key="index">
                    {{ item.desc }}
                  </p>
                </van-collapse-item>
              </van-collapse>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { get, put } from "@/utils/http";
export default {
  data() {
    return {
      tabIndex: 0,
      activeNames: [],
      orderlist: [],
      list: [],
      pageNum: 1,
      pageSize: 10,
      isload: true,
      billCode: "",
    };
  },
  created() {
    // console.log()
        this.tabIndex = this.$route.query.tabIndex?this.$route.query.tabIndex:0;
    this.billCode = this.$route.query.billCode?this.$route.query.billCode:'';
    this.getExpress();
  },
  methods: {
    load() {
      this.pageSize += 10;
      this.getExpress();
    },

    /**
     * 获取寄件记录
     */
    getExpress() {
      get("/express", {
        billCode: this.billCode,
        status: this.tabIndex,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res);
        // if(res.data.list.)
        this.orderlist = res.data.list;
      });
    },
    handleClick(tab, event) {
      this.tabIndex = tab;
      this.pageSize = 10;
      this.getExpress();
    },
    handleChange(id) {
      get(`/express/routeInfo/${id}`).then((res) => {
        if (res.data[0]) {
          this.list = res.data;
        } else {
          this.list = [
            {
              desc: "暂无记录",
            },
          ];
        }
      });
    },
    handlecancel(id) {
      put(`/express/cancel/${id}`).then((res) => {
        if (res.code == 200) {
          this.getExpress();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  background-color: #489ef6;
  .title {
    padding: 20px 0 0 20px;
    font-size: 20px;
    color: white;
    margin-bottom: 30px;
  }
  .body {
    background-color: #ffffff;
    padding-bottom: 50px;
  }
  .main {
    overflow: hidden;
    padding: 20px;
    border-bottom: 1px solid #bdbdbd;
    .body {
      padding-bottom: 0px;
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 18px;
        }
        span {
          font-size: 14px;
          color: #666666;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 18px;
        }
        span {
          font-size: 14px;
          color: #666666;
        }
      }
      .mid {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 80px;
        }
        span {
          font-size: 14px;
          color: #666666;
        }
      }
    }
    .li {
      margin-bottom: 10px;
    }
    .btn {
      // display: ;
      float: right;
    }
  }
}
.list {
  height: 500px;
}
</style>